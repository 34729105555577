import React from "react";
import { format } from "date-fns";
import styles from "./NotesCard.module.css";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import classnames from "classnames";
import {
  MONTH_SHORT_DAY_YEAR_TIME_FORMAT,
  DEFAULT_12_HOUR_TIME_FORMAT,
} from "features/Common/modules/DateTime/DateTime.constants";
import { ReadMoreWrapper } from "@my-scoot/exly-react-component-lib";
import { Avatar } from "@my-scoot/component-library-legacy";
import { orgPermissions } from "utils/OrgPermissions";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import { dataExists } from "utils/validations";
import { isEdited } from "../../utils/CrmNotes.utils";

const NotesCard = ({ data }) => {
  const isSelfNote = data?.role_type === orgPermissions.getRoleType();
  const isNoteEdited = isEdited({
    createdAt: data?.created_at,
    updatedAt: data?.updated_at,
  });

  return (
    <div
      className={classnames(
        styles.contentWrapper,
        isSelfNote && styles.contentSelfNoteWrapper
      )}
      id={data?.uuid}
    >
      {!isSelfNote &&
        (data?.display_image ? (
          <img
            src={data?.display_image}
            alt="display-image"
            className={styles.displayImage}
          />
        ) : (
          <Avatar
            colorIndex={0}
            label={data?.display_name}
            className={styles.displayImage}
          />
        ))}
      <div
        className={classnames(
          styles.note,
          isSelfNote ? styles.selfNote : styles.orgNote
        )}
      >
        {data?.is_active || !dataExists(data?.is_active) ? (
          <>
            <div className={styles.upperContent}>
              {!isSelfNote && (
                <span className={styles.displayNameStyle}>
                  {data?.display_name}
                </span>
              )}
              <ReadMoreWrapper
                childDivProps={{
                  id: "text",
                  className: classnames(
                    styles.messageStyle,
                    isSelfNote && styles.selfNoteMessageStyle
                  ),
                  dangerouslySetInnerHTML: { __html: data?.message },
                }}
                collapsedHeight={65}
                ExpandToggleButton={({ onClick, isExpanded }) => (
                  <div
                    onClick={onClick}
                    className={classnames(
                      styles.readMoreText,
                      isSelfNote && styles.selfNoteReadMoreText
                    )}
                  >
                    {isExpanded ? "see less" : "...see more"}
                  </div>
                )}
              ></ReadMoreWrapper>
            </div>
            <div
              className={classnames(
                styles.bottomContent,
                isSelfNote && styles.bottomContentSelfNoteSpanWrapper
              )}
            >
              <span
                className={classnames(
                  styles.bottomContentSpanWrapper,
                  isSelfNote && styles.bottomContentSelfNoteSpanWrapper
                )}
              >
                <img
                  src={`./assets/vectors/crm/${
                    isSelfNote ? "note_stack.svg" : "note_stack_ex.svg"
                  }`}
                  className={styles.noteIconStyle}
                />
                <span>Note</span>
              </span>
              {isNoteEdited && (
                <span
                  className={classnames(
                    styles.bottomContentSpanWrapper,
                    isSelfNote && styles.bottomContentSelfNoteSpanWrapper
                  )}
                >
                  <FiberManualRecordIcon className={styles.dotStyle} />
                  Edited
                </span>
              )}
              <span
                className={classnames(
                  styles.bottomContentSpanWrapper,
                  isSelfNote && styles.bottomContentSelfNoteSpanWrapper
                )}
              >
                <FiberManualRecordIcon className={styles.dotStyle} />
                {format(new Date(data.created_at), DEFAULT_12_HOUR_TIME_FORMAT)}
              </span>
            </div>
          </>
        ) : (
          <div
            className={classnames(
              styles.deletedWrapper,
              isSelfNote && styles.deletedSelfNoteWrapper
            )}
          >
            <NotInterestedIcon className={styles.deletedIcon} />{" "}
            <span className={styles.deletedText}>
              Note deleted on{" "}
              {format(
                new Date(data?.updated_at),
                MONTH_SHORT_DAY_YEAR_TIME_FORMAT
              )}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotesCard;
