import { isRequestSuccessful } from "utils/Utils";

export const generateTagModalProps = ({
  processing,
  setProcessing,
  setShowAddTagsModal,
  leadEmailId,
  setLeadEmailId,
  onTagsAssignPerRecord,
  onTagsAssign,
  selectedRecords,
  entityName,
  tableName,
  showAddTagsModal,
  feature,
}) => {
  const tagsModalProps = {
    ctaText: processing ? "..." : "Save",
    key: "AddTag",
    open: showAddTagsModal,
    onClose: () => {
      setLeadEmailId(null);
      setShowAddTagsModal(false);
    },
    feature,
    onSubmit: (values, tagArraylength) =>
      handleAssignTags({
        values,
        tagArraylength,
        processing,
        setProcessing,
        setShowAddTagsModal,
        leadEmailId,
        onTagsAssignPerRecord,
        setLeadEmailId,
        onTagsAssign,
        selectedRecords,
        entityName,
        tableName,
      }),
  };
  return tagsModalProps;
};

export const handleAssignTags = async ({
  values,
  tagArraylength,
  processing,
  setProcessing,
  setShowAddTagsModal,
  leadEmailId,
  onTagsAssignPerRecord,
  onTagsAssign,
  selectedRecords,
  entityName,
  tableName,
  showFirstTagsModal,
  setLeadEmailId,
}) => {
  try {
    if (processing) return;
    setProcessing(true);
    let emails = [];
    let status;
    const hideTagsModal = () => setShowAddTagsModal(false);

    if (leadEmailId) {
      emails.push(leadEmailId);
      const { status: onTagsAssignPerRecordStatus } =
        await onTagsAssignPerRecord(values, hideTagsModal, emails);
      setLeadEmailId(null);
      status = onTagsAssignPerRecordStatus;
    } else {
      const { status: tagsStatus } = await onTagsAssign({
        values,
        hideTagsModal,
        selectedRecords,
        tableName,
        entityName,
      });
      status = tagsStatus;
    }
    //Checking if array length is 0 and tag gets assigned then calling the first tag modal
    if (!tagArraylength && isRequestSuccessful(status)) showFirstTagsModal();
  } catch (err) {
    console.log(
      `webleads add tag onSubmit: ${err || "error while adding tag"}`
    );
  } finally {
    setProcessing(false);
  }
};
