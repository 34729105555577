import { format, isToday, isYesterday } from "date-fns";
import {
  MONTH_DAY_YEAR_FORMAT,
  YEAR_MONTH_DAY_FORMAT,
} from "features/Common/modules/DateTime/DateTime.constants";

export const groupDataByDate = ({ data }) => {
  const groupedData = {};

  // Iterate over the input data
  data.forEach((item) => {
    const date = format(new Date(item.created_at), YEAR_MONTH_DAY_FORMAT); // Extract the date part only

    // Check if the date key exists in the groupedData object
    if (!groupedData[date]) {
      // If not, create a new array for this date
      groupedData[date] = [];
    }

    // Push the entire item to the corresponding date's array
    groupedData[date].push(item);
  });

  // Get an array of the dates, sorted in ascending order
  const sortedDates = Object.keys(groupedData).sort(
    (a, b) => new Date(a) - new Date(b)
  );

  // Initialize an empty object to store the sorted grouped data
  const sortedGroupedData = {};

  sortedDates.forEach((date) => {
    // Sort each date's array by the created_at field in ascending order
    groupedData[date].sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );
    sortedGroupedData[date] = groupedData[date];
  });

  return sortedGroupedData;
};

export const getLastElementUniqueId = ({ data }) => {
  const [firstElement] = data;

  return firstElement?.uuid;
};

export const getDisplayDate = ({ date }) => {
  if (!date) return "";

  const dateObject = new Date(date);

  if (isToday(dateObject)) {
    return "Today";
  } else if (isYesterday(dateObject)) {
    return "Yesterday";
  } else {
    return format(dateObject, MONTH_DAY_YEAR_FORMAT);
  }
};
